import { store } from '@/store/store';
import { lineas_Pregunta } from '@/shared/dtos/lineas_Pregunta';
import {
    VuexModule,
    Mutation,
    getModule,
    Module,
    Action
} from 'vuex-module-decorators';
import { ssmHttpService } from '@/shared/services/http-service';
import { API } from '@/shared/api';

@Module({
    namespaced: true,
    name: 'lineas_preguntaModule',
    store,
    dynamic: true
})
class lineas_preguntaModule extends VuexModule {
    public lineas_preguntas: lineas_Pregunta[] = [];
    public lineas_pregunta: lineas_Pregunta = new lineas_Pregunta();

    @Action({ commit: 'onGetlineas_preguntas' })
    public async getlineas_preguntas() {
        return await ssmHttpService.get(API.lineas_pregunta);
    }

    @Action({ commit: 'onGetlineas_pregunta' })
    public async getlineas_pregunta(id: any) {
        return await ssmHttpService.get(API.lineas_pregunta + '/' + id);
    }

    @Action
    public async guardarlineas_pregunta(lineas_pregunta: lineas_Pregunta) {
        /*toJson() no existe pero existe en la extends de BaseDto*/
        await ssmHttpService.post(API.lineas_pregunta, lineas_pregunta.toJson());
        this.getlineas_preguntas();
    }

    @Action
    public async modificarlineas_pregunta(lineas_pregunta: lineas_Pregunta) {
        await ssmHttpService.put(API.lineas_pregunta, lineas_pregunta);
        this.getlineas_preguntas();
    }

    @Action
    public async eliminarlineas_pregunta(lineas_pregunta: lineas_Pregunta) {
        await ssmHttpService.delete(API.lineas_pregunta + '/' + lineas_pregunta.id, null, false);
        this.getlineas_preguntas();
    }

    @Mutation
    public onGetlineas_preguntas(res: lineas_Pregunta[]) {
        this.lineas_preguntas = res ? res.map((x) => new lineas_Pregunta(x)) : [];
    }

    @Mutation
    public onGetlineas_pregunta(res: lineas_Pregunta) {
        this.lineas_pregunta = new lineas_Pregunta(res);
    }

}
export default getModule(lineas_preguntaModule);