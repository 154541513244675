import { render, staticRenderFns } from "./paciente-hist-preguntas.vue?vue&type=template&id=72a8db8f&"
import script from "./paciente-hist-preguntas.vue?vue&type=script&lang=ts&"
export * from "./paciente-hist-preguntas.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports