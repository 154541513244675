













import { Component, Vue, Ref, Prop } from "vue-property-decorator";
import hist_preguntas_pacienteModule from "@/store/modules/hist_preguntas_paciente-module";
import { ClassColumnDataTable } from "@/components/DatatableGenerico/ClassColumnDataTable";
import { datatypes } from "@/components/DatatableGenerico/datatypes";
import cabezera_preguntaModule from "@/store/modules/cabezera_pregunta-module";
import lineas_preguntaModule from "@/store/modules/lineas_pregunta-module";

@Component({
  components: {
    DataTable: () => import("@/components/DatatableGenerico/DataTable.vue")
  }
})
export default class pacienteHistPreguntas extends Vue {
  @Ref("DataTable") DataTable!: any;
  @Prop({ type: Number }) id_paciente!: number;
  public async created() {
    if (this.id_paciente > 0) {
      await hist_preguntas_pacienteModule.gethist_preguntas_pacientes(
        this.id_paciente ? this.id_paciente : 0
      );
      await cabezera_preguntaModule.getcabezera_preguntascabezeras();
      await lineas_preguntaModule.getlineas_preguntas();
    }
  }
  public get historial() {
    return hist_preguntas_pacienteModule.hist_preguntas_pacientes;
  }

  public get Columns() {
    var Columnas: ClassColumnDataTable[] = [];
    let col1 = new ClassColumnDataTable(
      "fecha",
      "Fecha pregunta",
      datatypes.date,
      true,
      datatypes.maxlength
    );
    col1.groupindex = 0;
    Columnas.push(col1);
    let col2 = new ClassColumnDataTable(
      "id_pregunta",
      "Pregunta",
      datatypes.number,
      true,
      datatypes.maxlength
    ).CreateDxLookup(
      cabezera_preguntaModule.cabezera_preguntas,
      "pregunta",
      "id"
    );
    col2.width = 175;
    Columnas.push(col2);
    let col3 = new ClassColumnDataTable(
      "id_respuesta",
      "Respuesta",
      datatypes.number,
      true,
      datatypes.maxlength
    ).CreateDxLookup(lineas_preguntaModule.lineas_preguntas, "respuesta", "id");
    //col3.width = 300;
    Columnas.push(col3);
    return Columnas;
  }

  public Eliminar(id_row: number) {
    let hpp = this.historial.filter(x => (x.id = id_row))[0];
    hist_preguntas_pacienteModule.eliminarhist_preguntas_paciente(hpp);
  }
}
